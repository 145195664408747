import React from "react";
import styles from "./App.module.scss";

function App() {
  return (
    <div className={styles.container}>
      <header>
        <h1>
          <a href="https://hootcourse.com" target="_top">
            <img src={require("./logo.png")} alt="HootCourse Beta" />
          </a>
        </h1>
        <h2>HootCourse retires!</h2>
        <p>
          <strong>January 11, 2020</strong>
        </p>
      </header>
      <main>
        <p>
          Since launching in 2010, HootCourse has been home to thousands of
          course discussions comprised of millions of tweets, status updates,
          and blog posts. Teachers and students across the globe have used it to
          connect with each other, share information, and learn.
        </p>
        <p>All for free. No ads. No spam. No data selling.</p>
        <p>
          Our team is extremely proud that we were able to provide this service
          to the global community of educators and learners and that so many
          people found it useful. We pursued careers in technology to have a
          positive impact on the world and serving you has been one way we've
          honored that promise. The encouragement and support we've received in
          return over the years has been inspiring and humbling.
        </p>
        <p>
          The company that originally built HootCourse shutdown in 2011 as its
          founding team moved on to new challenges. Since then, we've kept
          HootCourse up and running by both volunteering our time to maintain it
          and donating our money to cover costs. Due to insufficient resources
          for ensuring system availability and security going forward, we've
          reluctantly decided that the time has finally come to retire it.
        </p>
        <p>
          <strong>
            Effective 2/1/2020, HootCourse will go offline permanently.
          </strong>
        </p>
        <p>
          Thank you to all of the teachers and learners that used our service.
          Thank you for your enthusiasm and support for using technology to
          improve education. Thank you for your dedication to learning and
          teaching.
        </p>
        <p>You make the world a better place. We'll miss you.</p>
        <h3>FAQ</h3>
        <h4>What will happen to my data?</h4>
        <p>
          We will incrementally shut down HootCourse's application, cache, and
          database servers. Backups and logs will be deleted. Our access to
          Twitter, Facebook, and other services will be revoked or deleted
          (preventing any further API access to user accounts on those services
          using our credentials).
        </p>
        <p>
          Data that users shared to other services (e.g., blog posts, Tweets,
          status updates) will remain published on those services. If posters
          wish to manage this content, they will need to work with the relevant
          service providers to do so.
        </p>
        <h4>What will happen to pages that I've embedded a HootCourse on?</h4>
        <p>
          The HootCourse tab will remain on those pages. When users click on the
          tab, they will see this message. Instructors will need to manually
          remove the tab from the pages they've placed it on.
        </p>
        <h4>Will you open source HootCourse?</h4>
        <p>
          The HootCourse implementation has been in maintenance-only mode for
          nearly 10 years. During that time, many new techniques and
          technologies for building real time web applications were developed.
          As it stands, HootCourse's implementation does not represent the state
          of the art. It would not be a good foundation for building a similar
          service. With that in mind, we've decided not to release HootCourse's
          source code at this time.
        </p>
        <p>
          <a href="https://welfley.me" target="_top">
            Thomas Welfley
          </a>
          , formerly HootCourse's lead developer, has volunteered to answer
          questions regarding implementation details and strategies for
          developing similar services.
        </p>
        <h4>What was HootCourse?</h4>
        <p>
          HootCourse was a real time backchannel for educators and learners that
          leveraged connected Facebook, Twitter, and blogging accounts. Learners
          could broadcast their course content related thoughts either publicly
          or just to other course members.
        </p>
        <p>
          In face to face environments, learners used it to share relevant
          information and ask questions without interrupting the flow of
          lectures or presentations. In online courses, instructors embedded it
          in their course materials to facilitate student to student and student
          to instructor engagement.
        </p>
        <p>
          <a
            href="https://www.youtube.com/results?search_query=HootCourse+-golf"
            target="_top"
          >
            Video demos from instructors around the world.
          </a>
        </p>
        <h4>
          I have a question that isn't answered here. How can I contact you?
        </h4>
        <p>
          You can contact the HootCourse team at{" "}
          <a href="mailto:info@hootcourse.com">info@hootcourse.com</a>.
        </p>
      </main>
      <footer>
        <p>&copy; Match Strike, LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
